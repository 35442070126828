<template>
  <v-dialog
    v-model="account"
    persistent
    max-width="650px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="ma-0 d-flex justify-space-between">
        <span class="text-h4 font-weight-bold primary--text">Gestión de Usuario</span>
        <v-btn icon plain @click="cerrar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-overlay
        :value="loadingUser || loading"
        color="white"
        absolute
        opacity=".8"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text>    
        <validation-observer ref="ADMIN_USER_FORM" tag="div">   
          <v-row>
            <v-col cols="12" md="6" lg="6" class="pb-1">
              <label-form text="Nombre Legal" />
              <validation-provider name="Nombre Legal" vid="userInfo.name" rules="required" v-slot="{ errors }">
                <v-text-field
                  :value="data.name"
                  v-model="userInfo.name"
                  outlined
                  filled
                  dense
                  prepend-inner-icon="mdi-account"
                  :error-messages="errors[0]"
                  class="theme--custom"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-1">
              <label-form text="Email" />
              <validation-provider name="Email" vid="userInfo.email" rules="required|email" v-slot="{ errors }">
                <v-text-field
                  :value="data.email"
                  v-model="userInfo.email"
                  outlined
                  filled
                  dense
                  :error-messages="errors[0]"
                  prepend-inner-icon="mdi-email-check"
                  class="theme--custom"
                />
              </validation-provider>
            </v-col>      
          </v-row>
          <v-row v-if="roles_user != null">
            <v-col cols="12" class="pt-1 pb-1">
              <label-form text="Rol de usuario" required/>
               <validation-provider name="Rol de Usuario" vid="userInfo.rol" rules="required" v-slot="{ errors }">
              <v-select
                v-model="userInfo.rol"
                :items="roles_user"
                dense
                chips
                small-chips
                item-text="name"
                item-value="name"
                multiple
                outlined
                clearable
                :error-messages="errors[0]"
                :menu-props="{ bottom: true, offsetY: true }"
              />
               </validation-provider>
            </v-col>
          </v-row>        
          <v-row>
            <v-col cols="12" md="6" lg="6" class="mb-0 py-0">
              <label-form text="Contraseña" required/>
              <validation-provider name="Contraseña" vid="password" rules="min:8" :rules="{required: newUser}" v-slot="{ errors }">
                <v-text-field
                  v-model="userInfo.password"
                  outlined
                  dense
                  counter
                  persistent-hint
                  hint="Mínimo 8 caracteres"
                  :type="viewPass ? 'text' : 'password'"
                  :append-icon="viewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :error-messages="errors[0]"
                  @click:append="viewPass = !viewPass"
                >
                </v-text-field>
                <div class="px-3 pb-4 pt-1">
                  <v-progress-linear
                    v-model="check.porcent"
                    :color="check.color"
                    rounded
                    height="8"
                  ></v-progress-linear>
                  <span class="blue-grey--text text--darken-3 caption">Nivel de Seguridad: </span>
                  <span class="blue-grey--text text--darken-3 caption font-weight-bold" v-text="check.level"/>
                </div>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="mb-0 py-0">
              <label-form text="Repetir Contraseña" required/>
              <validation-provider name="Repetir Contraseña" vid="confirmPassw" :rules="{required: newUser}" rules="confirmed:password" v-slot="{ errors }">
                <v-text-field
                  v-model="confirmPassw"
                  outlined
                  dense
                  :type="confirmPass ? 'text' : 'password'"
                  :append-icon="confirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :error-messages="errors[0]"
                  @click:append="confirmPass = !confirmPass"
                >
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="mb-0 py-0">
              <v-list
                three-line
                class="pa-0 ma-0"
              >
                <v-list-item class="pa-0 ma-0">
                  <v-list-item-action class="">
                    <v-switch
                      v-model="userInfo.status"
                      color="secondary"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="display font-weight-bold">Habilitar Usuario</v-list-item-title>
                    <v-list-item-subtitle>Opción para habilitar o deshabilitar usuario del sistema web administrativo o app mobil.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </validation-observer>        
      </v-card-text>
      <v-card-actions class="d-flex justify-end mb-3">
        <v-btn
          tile
          depressed
          color="blue-grey lighten-5"
          class="px-8 mx-2"
          :small="$vuetify.breakpoint.mobile"
          @click="cerrar()"
        >
          Cancelar
        </v-btn>
        <v-btn
          tile
          depressed
          color="primary"
          class="px-8"
          :small="$vuetify.breakpoint.mobile"
          @click="addUser"
        >
          <v-icon dark left>mdi-account-edit-outline</v-icon>
          {{action === 'editar' ? 'Actualizar' : 'Crear Usuario'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { passwordStrength } from '@/util/PasswordCheck'
const dataUser = () => ({
  name: '',
  password: '',
  email: '',
  co_ven: '',
  status: true,
  rol: [],
});
export default {
  name:'ModalAccount',
  props:{
    value: Boolean,
    data:{
      type: Object,
      default: () => ({}),
    },
    action: {
      type: String,
      default: 'editar',
    },
    loading:{
      type: Boolean,
      default: false
    },
    roles:{
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      account: this.value,
      viewPass: false,
      confirmPass: false,
      loadingUser: false,
      userInfo: dataUser(),
      confirmPassw: '',
      reload_roles: false,
    }
  },
  watch: {
    account (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.account = val
    },
    data(val) {
      if(Object.values(val).length > 0) {
        this.userInfo =
        {
          ...val,
          password: '',
          rol: val.roles.map(item => item.name)
        };
      }
      else
        this.userInfo = dataUser();
    }
  },
  methods: {
    cerrar() {
      this.account = false;
      this.userInfo = dataUser();
      this.confirmPassw = '';
      this.$refs.ADMIN_USER_FORM.reset();
    },
    async addUser() {
      const valid = await this.$refs.ADMIN_USER_FORM.validate();
      if(valid) {
        try {
          this.loadingUser = true;
          const { data } = await this.$api({
            method: this.action === 'crear' ? 'post' : 'put',
            url: this.action === 'crear' ? 'users/crear' : 'users/' + this.userInfo.id,
            data:  this.userInfo,
          });

          this.loadingUser = false;
          this.cerrar();
          this.$parent.$emit('procesado');
          this.$root.$showAlert(data.message, 'success');
        } catch (error) {
            this.loadingUser = false;
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar crear el usuario.',
              'error'
            );
        }
      }
    },
    async getRoles(){
      try {
        const {data} = await this.$api.get('users/roles');
        this.roles = data.data;
        this.reload_roles = false;

      } catch (error) {
        this.reload_roles = true;
      }
    },
  },
  computed:{
    newUser(){
      return this.data.user === null
    },
    check(){
      const {value, porcent} = passwordStrength(this.userInfo.password);

      return{
        level: value,
        porcent: porcent,
        color: porcent === 25 ? 'blue-grey' : porcent === 50 ? 'deep-orange' : porcent === 75 ? 'blue accent-3' : porcent === 100 ?  'green accent-3' : 'blue-grey lighten-2'
      }
    },
    roles_user(){
      return this.roles.length > 0 ? this.roles.map(function(rol) {
        return {
          name: rol.name
        }
      }) : null;
    }
  }

}
</script>
