var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"650px","scrollable":"","content-class":"content-scroll"},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"ma-0 d-flex justify-space-between"},[_c('span',{staticClass:"text-h4 font-weight-bold primary--text"},[_vm._v("Gestión de Usuario")]),_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.cerrar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingUser || _vm.loading,"color":"white","absolute":"","opacity":".8"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-card-text',[_c('validation-observer',{ref:"ADMIN_USER_FORM",attrs:{"tag":"div"}},[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('label-form',{attrs:{"text":"Nombre Legal"}}),_c('validation-provider',{attrs:{"name":"Nombre Legal","vid":"userInfo.name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"theme--custom",attrs:{"value":_vm.data.name,"outlined":"","filled":"","dense":"","prepend-inner-icon":"mdi-account","error-messages":errors[0]},model:{value:(_vm.userInfo.name),callback:function ($$v) {_vm.$set(_vm.userInfo, "name", $$v)},expression:"userInfo.name"}})]}}])})],1),_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('label-form',{attrs:{"text":"Email"}}),_c('validation-provider',{attrs:{"name":"Email","vid":"userInfo.email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"theme--custom",attrs:{"value":_vm.data.email,"outlined":"","filled":"","dense":"","error-messages":errors[0],"prepend-inner-icon":"mdi-email-check"},model:{value:(_vm.userInfo.email),callback:function ($$v) {_vm.$set(_vm.userInfo, "email", $$v)},expression:"userInfo.email"}})]}}])})],1)],1),(_vm.roles_user != null)?_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-1",attrs:{"cols":"12"}},[_c('label-form',{attrs:{"text":"Rol de usuario","required":""}}),_c('validation-provider',{attrs:{"name":"Rol de Usuario","vid":"userInfo.rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles_user,"dense":"","chips":"","small-chips":"","item-text":"name","item-value":"name","multiple":"","outlined":"","clearable":"","error-messages":errors[0],"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.userInfo.rol),callback:function ($$v) {_vm.$set(_vm.userInfo, "rol", $$v)},expression:"userInfo.rol"}})]}}],null,false,2238124577)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mb-0 py-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('label-form',{attrs:{"text":"Contraseña","required":""}}),_c('validation-provider',{attrs:{"name":"Contraseña","vid":"password","rules":"min:8","rules":{required: _vm.newUser}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","counter":"","persistent-hint":"","hint":"Mínimo 8 caracteres","type":_vm.viewPass ? 'text' : 'password',"append-icon":_vm.viewPass ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors[0]},on:{"click:append":function($event){_vm.viewPass = !_vm.viewPass}},model:{value:(_vm.userInfo.password),callback:function ($$v) {_vm.$set(_vm.userInfo, "password", $$v)},expression:"userInfo.password"}}),_c('div',{staticClass:"px-3 pb-4 pt-1"},[_c('v-progress-linear',{attrs:{"color":_vm.check.color,"rounded":"","height":"8"},model:{value:(_vm.check.porcent),callback:function ($$v) {_vm.$set(_vm.check, "porcent", $$v)},expression:"check.porcent"}}),_c('span',{staticClass:"blue-grey--text text--darken-3 caption"},[_vm._v("Nivel de Seguridad: ")]),_c('span',{staticClass:"blue-grey--text text--darken-3 caption font-weight-bold",domProps:{"textContent":_vm._s(_vm.check.level)}})],1)]}}])})],1),_c('v-col',{staticClass:"mb-0 py-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('label-form',{attrs:{"text":"Repetir Contraseña","required":""}}),_c('validation-provider',{attrs:{"name":"Repetir Contraseña","vid":"confirmPassw","rules":{required: _vm.newUser},"rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":_vm.confirmPass ? 'text' : 'password',"append-icon":_vm.confirmPass ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors[0]},on:{"click:append":function($event){_vm.confirmPass = !_vm.confirmPass}},model:{value:(_vm.confirmPassw),callback:function ($$v) {_vm.confirmPassw=$$v},expression:"confirmPassw"}})]}}])})],1),_c('v-col',{staticClass:"mb-0 py-0",attrs:{"cols":"12"}},[_c('v-list',{staticClass:"pa-0 ma-0",attrs:{"three-line":""}},[_c('v-list-item',{staticClass:"pa-0 ma-0"},[_c('v-list-item-action',{},[_c('v-switch',{attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.userInfo.status),callback:function ($$v) {_vm.$set(_vm.userInfo, "status", $$v)},expression:"userInfo.status"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"display font-weight-bold"},[_vm._v("Habilitar Usuario")]),_c('v-list-item-subtitle',[_vm._v("Opción para habilitar o deshabilitar usuario del sistema web administrativo o app mobil.")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end mb-3"},[_c('v-btn',{staticClass:"px-8 mx-2",attrs:{"tile":"","depressed":"","color":"blue-grey lighten-5","small":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"px-8",attrs:{"tile":"","depressed":"","color":"primary","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.addUser}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-account-edit-outline")]),_vm._v(" "+_vm._s(_vm.action === 'editar' ? 'Actualizar' : 'Crear Usuario')+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }